<template>
  <section
    id="overview"
    class=""
  >
    <create-article
      v-if="user"
      ref="createArticleComponent"
    />
    <core-jumbotron-mobile />
    <heading>{{ $t('news.title') }}</heading>
    <section
      v-if="articlesLoaded"
    >
      <v-card
        v-for="article in articles"
        :key="article.id"
        class="ma-3 pa-5"
        light
        outlined
        tile
        elevation="0"
      >
        <v-list-item>
          <v-list-item-avatar
            tile
            width="120px"
            height="50px"
            class="hidden-sm-and-down"
          >
            <v-img
              v-if="article.image"
              style="border-radius: 4px 0px 0px 4px;"
              class="v-responsive"
              :src="article.image"
            />
          </v-list-item-avatar>
          <v-list-item-content
            class="pr-10"
          >
            <small>{{ moment(new Date(article.date.toMillis())).format('MMMM Do YYYY') }}</small>
            <a
              v-if="article.document"
              :href="article.document"
              target="'_blank"
              class="black--text"
            >
              <v-list-item-title class="title text-wrap">{{ article.title }}</v-list-item-title>
            </a>
            <v-list-item-title
              v-else
              class="title text-wrap"
            >
              {{ article.title }}
            </v-list-item-title>
            {{ article.body }}
            <v-btn
              v-if="user"
              class="mt-10 col-3"
              @click="editArticle(article)"
            >
              Edit
            </v-btn>
            <v-btn
              v-if="user"
              class="mt-10 col-3 offset-1"
              @click="copyArticle(article)"
            >
              Copy
            </v-btn>
            <v-btn
              v-if="user"
              dark
              class="mt-10 col-3 offset-1 red"
              @click="deleteArticle(article)"
            >
              Delete
            </v-btn>
          </v-list-item-content>

          <a
            v-if="article.document"
            :href="article.document"
            target="'_blank"
            class="hidden-sm-and-down"
          >
            <v-list-item-action class="text-center">
              <v-icon light>mdi-download</v-icon>
            </v-list-item-action>
          </a>

          <a
            v-if="article.twitter"
            :href="article.twitter"
            target="'_blank"
            class="hidden-sm-and-down"
          >
            <v-list-item-action class="text-center">
              <v-icon light>mdi-twitter</v-icon>
            </v-list-item-action>
          </a>
          <a
            v-if="article.facebook"
            :href="article.facebook"
            target="'_blank"
            class="hidden-sm-and-down"
          >
            <v-list-item-action class="text-center">
              <v-icon light>mdi-facebook</v-icon>
            </v-list-item-action>
          </a>
          <a
            v-if="article.youtube"
            :href="article.youtube"
            target="'_blank"
            class="hidden-sm-and-down"
          >
            <v-list-item-action class="text-center">
              <v-icon light>mdi-youtube</v-icon>
            </v-list-item-action>
          </a>
          <a
            v-if="article.linkedin"
            :href="article.linkedin"
            target="'_blank"
            class="hidden-sm-and-down"
          >
            <v-list-item-action class="text-center">
              <v-icon light>mdi-linkedin</v-icon>
            </v-list-item-action>
          </a>
        </v-list-item>
      </v-card>
    </section>
  </section>
</template>
<style>
  a  {text-decoration:none}
</style>

<script>
  import i18n from '../i18n'
  import moment from 'moment'
  export default {
    metaInfo: {
      title: i18n.t('menu.news_articles'),
      meta: [
        { name: 'description', content: 'Oria Agriculture' },
      ],
    },

    components: {
      CreateArticle: () => import('@/components/account/CreateArticle'),
      Heading: () => import('@/components/Heading'),
      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
    },

    data () {
      return {
        user: null,
        articles: [],
        articlesLoaded: false,
      }
    },
    watch: {
      // call again the method if the route changes
      $route: 'fetchArticles',

    },
    created: function () {
      this.fetchArticles()
      const vm = this
      this.$firebaseAuth.onAuthStateChanged(function (user) {
        if (user) {
          vm.user = user
        } else {
          vm.user = null
        }
      })
    },
    methods: {
      moment,
      editArticle (article) {
        this.$refs.createArticleComponent.editArticle(article)
      },
      copyArticle (article) {
        this.$refs.createArticleComponent.copyArticle(article)
      },
      deleteArticle (article) {
        var _this = this
        this.$confirm('Are you certain you wish to permanently delete this article?', { icon: 'mdi-alert' }).then(res => {
          if (res) {
            _this.$firebaseDatabase.collection('articles').doc(article.id).delete().then(res => {
              _this.fetchArticles()
            })
          }
        })
      },
      fetchArticles () {
        this.articles = []
        const articlesRef = this.$firebaseDatabase.collection('articles')
        var _this = this
        articlesRef.where('lang', '==', i18n.locale).orderBy('date', 'desc').get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            const article = doc.data()
            article.id = doc.id
            _this.articles.push(article)
          })
          _this.articlesLoaded = true
        })
      },
    },
  }
</script>
